.modalHeader {
  display: flex;
  justify-content: flex-end;
}

.close {
  @apply p-4;
  @apply text-hubRed bg-hubRed bg-opacity-10;
  @apply hover:text-white hover:bg-opacity-100;
}
