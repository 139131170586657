.textPrompt {
  @apply text-lg;
  text-align: center;
  line-height: 30px;
}

.actionBtns button {
  @apply px-10 py-4;
  color: #fff;
  font-size: 18px;
  border-radius: 5px;
  font-weight: 500;
}

.actionBtns button:not(:last-child) {
  margin-right: 10px;
}

button.confirmBtn {
  @apply text-hubGreen bg-hubGreen bg-opacity-20;
  @apply hover:text-white hover:bg-opacity-100;
}

.actionBtns.blue .confirmBtn {
  @apply text-hubBlueLt bg-hubBlueLt bg-opacity-20;
  @apply hover:text-white hover:bg-opacity-100;
}

button.declineBtn {
  @apply text-hubRed bg-hubRed bg-opacity-20;
  @apply hover:text-white hover:bg-opacity-100;
}
