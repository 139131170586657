.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  min-height: 75vh;
}

.headerTextIcon svg {
  color: #cedff3;
}

.headerText h1 {
  @apply text-lg;
  line-height: 32px;
}

.headerText p {
  font-size: 16px;
  line-height: 19px;
  color: rgb(34 34 34 / 50%);
}

.container {
  @apply bg-white;
}

.createMsg {
  @apply text-lg font-medium rounded;
  @apply flex justify-center items-center p-5;
  @apply bg-white text-hubBlueLt hover:bg-hubBlueLt hover:text-white;
}

.listShowMore {
  @apply my-10 flex flex-col gap-y-3;
  padding: 0 20px 0 0;
  max-height: 440px;
  overflow-x: auto;
}

.listShowMore > p {
  @apply rounded-md bg-hubGrayLt bg-opacity-60 px-5 py-4 hover:bg-hubGreen hover:text-white;
}

.listShowMore::-webkit-scrollbar {
  width: 28px;
}

.listShowMore::-webkit-scrollbar-track {
  background: #f2f2f2;
  box-sizing: border-box;
  border-radius: 3px;
}

.listShowMore::-webkit-scrollbar-thumb {
  @apply bg-white;
  border: 4px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 3px;
}

.listShowMore::-webkit-scrollbar-thumb:hover {
  @apply bg-hubGreen;
}

.editor > div:nth-of-type(2) {
  overflow-y: scroll;
  min-height: 10rem;
  max-height: 22rem;
  resize: vertical !important;
}

.iconReceiver {
  @apply flex items-center;
}

.iconReceiver .icon {
  @apply rounded-md p-3 cursor-pointer;
  @apply text-hubGrayLt;
  border: 3px solid;
}

.iconReceiver .iconActive {
  @apply rounded-md p-3 cursor-pointer;
  @apply text-hubBlueLt bg-hubBlueLt bg-opacity-20;
  border: 3px solid #0000;
}

.iconLabel {
  @apply ml-3 text-reg font-light;
}

.iconLabel p:first-child {
  @apply text-lg font-normal;
}
