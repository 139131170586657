.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  min-height: 75vh;
}

.headerTextIcon svg {
  color: #cedff3;
}

.headerText h1 {
  @apply text-lg;
  line-height: 32px;
}

.headerText p {
  font-size: 16px;
  line-height: 19px;
  color: rgb(34 34 34 / 50%);
}

.container {
  @apply bg-white;
}

.rowHolder {
  @apply flex flex-col gap-y-2;
}

.rowHolder > div {
  @apply bg-white flex p-3;
}

.editBtn {
  @apply flex items-center font-medium text-hubBlueLt;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  font-size: 16px;
  line-height: 19px;
  border-radius: 3px;
  padding: 12px 15px;
}

.removeBtn {
  @apply flex items-center font-medium text-hubRed;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  font-size: 16px;
  line-height: 19px;
  border-radius: 3px;
  padding: 12px 15px;
}

.actionBtns {
  min-width: 220px;
}

.orangeTag {
  @apply bg-hubOrange bg-opacity-20 text-hubOrange text-sm p-2;
}
