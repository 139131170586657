.mainContainer {
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
}

.headerTextIcon svg {
  color: #cedff3;
}

.headerText h1 {
  @apply text-lg;
  line-height: 32px;
}

.headerText p {
  font-size: 16px;
  line-height: 19px;
  color: rgb(34 34 34 / 50%);
}

.container {
  @apply bg-white;
}

.container > div:not(:last-child) {
  @apply pb-10;
  @apply mb-10;
  border-bottom: 1px solid #ebebeb;
}
