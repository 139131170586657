.title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
  
    text-align: center;
  
    color: #272828;
  }
  
  .desc {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 28px;
    /* identical to box height, or 165% */
    text-align: center;
    color: #222222;
  }
  
  .dropdown {
    width: 274px;
    height: 50px;
    border: 1px solid #000000 !important;
    box-sizing: border-box !important;
    border-radius: 3px !important;
    text-align: center;
  
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    /* or 156% */
  
    color: #000000;
  }
  
  .label {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
  
    color: #272828;
  }
  
  .dateInput {
    width: 274px;
    height: 50px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  
    color: #222222;
  }
  
  .blueBtn {
    width: 160px;
    height: 55px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  
    color: #388cef;
    background: rgba(56, 140, 239, 0.12);
    border-radius: 5px;
  }
  
  .blueBtn:hover {
    background-color: #388cef;
    color: white;
  }
  
  .redBtn {
    width: 160px;
    height: 55px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  
    color: #f56666;
    background: rgba(245, 102, 102, 0.12);
    border-radius: 5px;
  }
  
  .redBtn:hover {
    background-color: #f56666;
    color: white;
  }
  
  .isNotManualDesc {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 28px;
    /* or 165% */
  
    text-align: justify;
  
    color: #f56666;
  }
  