.header {
  @apply bg-black;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1440px;
  margin: auto;
  box-sizing: border-box;
  position: relative;
}

.menu {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.buttons {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
}

.buttons > * {
  @apply mr-2;
}

.menuList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.menuList li > a {
  text-transform: uppercase;
  color: #fff;
  padding: 25px 15px;
}

.menuList li > a:hover,
.menuList li > a.active {
  @apply bg-white bg-opacity-20;
}

.menuList li:hover .subMenu {
  display: block;
}

.subMenu {
  @apply bg-hubBlack;
  position: absolute;
  top: 100%;
  z-index: 9;
  width: 180px;
  display: none;
}

.subMenu li > a {
  display: block;
}

@media screen and (min-width: 1100px) {
  .row .logo {
    width: 15%;
  }

  .row .menu {
    width: 85%;
  }
}
