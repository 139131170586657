.heading h3 {
  @apply text-lg;
  line-height: 32px;
}

.heading p {
  font-size: 16px;
  line-height: 19px;
  color: rgb(34 34 34 / 50%);
}

.summary {
  @apply flex flex-col gap-y-1;
  @apply p-5;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 5px;
}

.value {
  @apply text-lg;
  display: flex;
  align-items: center;
}

.value p:first-child {
  @apply mr-2;
  @apply text-2xl;
  color: theme("colors.hubBlueLt");
}
