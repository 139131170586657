.container {
  @apply flex flex-col;
}

.container > button {
  @apply flex items-center p-4 pr-8 cursor-pointer;
  @apply active:opacity-70 hover:opacity-80;
  font-size: 16px;
}

.container > button:not(:last-child) {
  @apply border-b border-hubGray border-opacity-50;
}

.container > button > svg {
  @apply mr-2;
}

.loading:after {
  content: " .";
  font-size: 2rem;
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}
