.list {
  @apply my-10 flex flex-col gap-y-3 pr-8;
  max-height: 440px;
  overflow-x: auto;
}

.list > p {
  @apply rounded-md bg-hubGrayLt bg-opacity-60 px-5 py-4 hover:bg-hubGreen hover:text-white;
}

p.selected {
  @apply bg-hubGreen text-white;
}

.list::-webkit-scrollbar {
  width: 28px;
}

.list::-webkit-scrollbar-track {
  background: #f2f2f2;
  box-sizing: border-box;
  border-radius: 3px;
}

.list::-webkit-scrollbar-thumb {
  @apply bg-white;
  border: 4px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 3px;
}

.list::-webkit-scrollbar-thumb:hover {
  @apply bg-hubGreen;
}

.checkbox {
  align-items: center;
  display: inline-flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 16px;
  top: 8px;
  width: 9px;
  height: 20px;
  border: solid #e7e5e5;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkmark {
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  display: inline-block;
}

.selected .checkmark,
p:hover .checkmark {
  background-color: #ffffff;
  border: 1px solid #7bae3e;
}

.selected .checkmark:after,
p:hover .checkmark:after {
  border: solid #8ac04a;
  border-width: 0 3px 3px 0;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
